<template>
    <div id="product-page">
        <div id="edit-product-container" v-if="ulog">
            <v-btn
                id="edit-product-btn" 
                color="error"
                @click="editProductOverlay = !editProductOverlay"
                >
                Edit Product
            </v-btn>
        </div>
        <div id="product-item">
            <h1 id="item-name">{{currentItem.name}}</h1>
            <v-img id="item-image" :src="currentItem.imageUrl" alt="Shop item image" />
            <div id="item-info">
                <p id="item-desc" v-html="productDescriptionToHtml"></p>
                <hr>
                <p id="item-price">${{currentItem.price}} CAD</p>
                <v-menu
                    v-if="itemSizes.length >= 1"
                    id="sizes-menu"
                    bottom
                    origin="center center"
                    transition="scale-transition"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        id="choose-size-btn"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        >
                        {{chosenSize}}
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                        id="sizes-list"
                        v-for="(size, i) in itemSizes"
                        :key="i"
                        @click="chooseSize(size)"
                        >
                        <v-list-item-title>{{ size }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn 
                    @click="addToCart()" 
                    id="cart-button"
                    :disabled="itemSizes.length >= 1 && chosenSize == 'Choose Size'"
                    >
                    Add To Cart
                </v-btn>
            </div>
        </div>
        <v-overlay
            id="edit-product-overlay"
            v-if="ulog"
            :z-index="zIndex"
            :value="editProductOverlay"
            :opacity="opacity"
            :absolute="absolute"
            >
            <div id="edit-product-form-container">
                <EditProductForm :product="currentItem" @changeOverlay="editProductOverlay = !editProductOverlay"/>
            </div>
        </v-overlay>
    </div>
</template>

<script>
import axios from 'axios'
import router from '../router'
import cookies from 'vue-cookies'
import { eventBus } from '../main'
import { marked } from 'marked'
import EditProductForm from '../components/EditProductForm.vue'

    export default {
        name: "Products",
        components: {
            EditProductForm
        },
        computed: {
            productDescriptionToHtml() {
                if (this.item.description != null) {
                    return marked(this.item.description); // marked is package to parse user input to markdown/html. 
                }
                else {
                    return null;
                }
            },
            shopCart() {
                return this.$store.state.shoppingCart;
            },
            currentItem() {
                return this.item;
            },
            sessionCookie() {
                return cookies.get('_casLt'); 
            }
        },
        beforeMount() {
            this.getProduct()
        },
        mounted() {
            if (this.sessionCookie != undefined){
                this.ulog = true
            }
        },
        data() {
            return {
                item: {},
                itemSizes: [],
                chosenSize: "Choose Size",
                ulog: false,
                pageOverlayFlow: false,
                editProductOverlay: false,
                zIndex: 98,
                opacity: 1,
                absolute: true
            }
        },
        methods: {
            getProduct() {
                let productId = this.$route.params.id;
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/shop_products',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        'id': productId,
                    }
                }).then((response) => {
                    this.item = response.data[0];
                    
                    //get size list
                    if (this.item.sizes != null && this.item.sizes != "") {
                        this.itemSizes = this.item.sizes.split(', ');
                    }
                }).catch(() => {
                    router.push('/shop');
                });
            },
            addToCart() {
                let sendItem = this.item;
                
                //append size to item if required
                //current size stored as "old size" and new updated size stored as the new chosen size
                //this gets appended together in mutation
                if (this.itemSizes.length >= 1) {
                    let currentSize = sendItem["chosenSize"];
                    sendItem["chosenSize"] = this.chosenSize;
                    sendItem["oldSize"] = currentSize;
                }

                this.$store.commit("addItemToCart", sendItem);
                eventBus.$emit('showNavBar');

                //reset sizes
                this.chosenSize = "Choose Size";

            },
            chooseSize(size) {
                this.chosenSize = size;
            }
        }
    }
</script>

<style lang="scss" scoped>

    #product-page {
        width: 100vw;
        background-color: white;
        min-height: calc(100vh - 140px);
        margin-top: 70px; //account for fixed nav-bar height
        margin-bottom: 70px; //account for absolute footer height
        box-shadow: 0 5px 15px  rgb(131, 131, 131);

        #edit-product-container {
            width: 100%;
            padding: 15px 20px 0 20px;
            display: flex;
            justify-content: end;

            #edit-product-btn {
                width: fit-content;
                padding: 15px 20px;
            }
        }

        #product-item {
            font-family: "Helvetica";
            display: grid;
            
            #item-name {
                margin: 10px 0;
            }

            #item-image {
                width: 100%;
                max-width: 500px;
                object-fit: cover;
                justify-self: center;
            }

            #item-info {
                margin: 20px 0;
                padding: 0 10px;
                display: grid;

                #item-price {
                    margin: 10px 0;
                    font-size: 1.5em;
                    font-weight: bold;
                }

                #choose-size-btn {
                    display: block;
                    margin: 10px 0;
                    width: fit-content;
                    padding: 0 30px;
                }

                #cart-button {
                    min-width: 165px;
                    width: 95%;
                    justify-self: center;
                    height: 50px;
                    letter-spacing: 0.5px;
                    line-height: 50px;
                    padding: 0 35px;
                    margin: 20px 0;
                    font-size: 15px;
                    background-color: white;
                    color: black;
                    border: 1px solid black;
                    text-transform: uppercase;
                    font-family: "Helvetica";
                    font-weight: bolder;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;

                    &:hover {
                        background-color: black;
                        color: white;
                        border: none;
                    }
                }
            }
        }

        #edit-product-overlay {
            display: grid;
            min-height: 100vh;
            height: fit-content;

            #edit-product-form-container {
                width: 90vw;
                margin-bottom: 4vh;
            }
        }
    }

@media screen and (min-width: 600px) {

    #product-page {
        margin-bottom: 150px; //account for absolute footer height

        #product-item {
            
            #item-name {
                justify-self: center;
                font-size: 2.5em;
            }

            #item-image {
                max-width: 600px;
            }

            #item-desc {
                font-size: 1.1em;
            }

            #item-info {
                width: 100%;
                max-width: 600px;
                justify-self: center;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    #product-page {
        max-width: 1350px;
        min-height: calc(100vh - 230px);
        margin-top: 80px; //account for fixed nav-bar height
        display: grid;

        #product-item {
            grid-template-columns: 60% 40%;
            grid-template-rows: 80px auto;
            margin: 20px 0;
            
            #item-name {
                grid-column: 2;
                grid-row: 1;
            }

            #item-image {
                width: 90%;
                align-self: center;
                grid-row: 1 / 3;
            }

            #item-info {
                grid-column: 2;
                grid-row: 2;
                grid-template-rows: auto 60px 60px 60px;

                #item-desc {
                    font-size: 1.2em;
                }

                hr {
                    align-self: end;
                }

                #item-price {
                    margin: 10px 0;
                    font-size: 1.5em;
                    font-weight: bold;
                }

                #choose-size-btn {
                    display: block;
                    margin: 10px 0;
                    width: fit-content;
                    padding: 0 30px;
                }

                #cart-button {
                    justify-self: start;

                    &:hover {
                        background-color: black;
                        color: white;
                        border: none;
                    }
                }
            }
        }
    }
}
</style>