<template>
    <div id="edit-product" @click="hideMsg">
        <h1>Edit this product</h1>
        <v-form id="edit-product-form" v-model="formValid">
            <v-text-field
                v-model="name"
                :rules="[nameRules.required, nameRules.min, nameRules.max]"
                color="primary"
                required
                >
                <template v-slot:label>
                    Name<span class="red--text"> *</span>
                </template>
            </v-text-field>
            <v-text-field
                v-model="imageUrl"
                :rules="[imageUrlRules.max]"
                label="Image Url"
                color="primary"
            ></v-text-field>
            <v-text-field
                v-model="price"
                :rules="[priceRules.required]"
                color="primary"
                type="number"
                required
                >
                <template v-slot:label>
                    Price<span class="red--text"> *</span>
                </template>
            </v-text-field>
            <p>Please re-enter the new stripe price code below. Found in Products on the Stripe Dashboard.</p>
            <v-text-field
                v-model="priceCode"
                :rules="[priceCodeRules.required, priceCodeRules.min, priceCodeRules.max]"
                color="primary"
                required
                >
                <template v-slot:label>
                    Stripe price code<span class="red--text"> *</span>
                </template>
            </v-text-field>
            <v-text-field
                v-model="sizes"
                :rules="[sizesRules.max]"
                label="Sizes (comma separated)"
                color="primary"
            ></v-text-field>
            <v-textarea
                v-model="description"
                :rules="[descriptionRules.max]"
                label="Description"
                color="primary"
                counter
                maxlength="500"
                auto-grow
            ></v-textarea>
            <div id="edit-product-error-message">
                <h3>{{errorMsg}}</h3>
            </div>
            <div id="form-btns">
                <v-btn id="edit-btn"
                    :disabled="!formValid"
                    color="success" 
                    @click="updateProduct()">
                        Save
                </v-btn>
                <v-btn
                    id="delete-btn"
                    class="white--text"
                    color="error"
                    @click="hideShowBtns()"
                >
                    Delete Product
                </v-btn>
                <v-btn
                    id="sure-btn"
                    class="white--text"
                    color="#FF0000"
                    @click="deleteProduct()"
                >
                    Click again to confirm delete 
                </v-btn>
                <v-btn
                    id="overlay-close-btn"
                    class="white--text"
                    color="primary"
                    @click="changeOverlay()"
                >
                    Close
                </v-btn>
            </div>
        </v-form>
        <MarkdownHtmlExamples />
    </div>
</template>

<script>
import axios from 'axios'
import router from '../router'
import cookies from 'vue-cookies'
import MarkdownHtmlExamples from '../components/MarkdownHtmlExamples.vue'

    export default {
        name: "EditProductForm",
        props: ["product"],
        components: {
            MarkdownHtmlExamples
        },
        mounted() {
            console.log(this.product);
        },
        data() {
            return {
                formValid: false,
                errorMsg: '',
                name: this.product.name,
                nameRules: {
                    required: v => !!v || 'Name is required',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 150 || 'Max 150 characters',
                },
                imageUrl: this.product.imageUrl,
                imageUrlRules: {
                    max: v => v.length <= 500 || 'Max 500 characters',
                },
                price: this.product.price,
                priceRules: {
                    required: v => !!v || 'Price is required',
                },
                priceCode: '',
                priceCodeRules: {
                    required: v => !!v || 'Price Code is required',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 100 || 'Max 100 characters',
                },
                sizes: this.product.sizes,
                sizesRules: {
                    max: v => v.length <= 100 || 'Max 100 characters',
                },
                description: this.product.description,
                descriptionRules: {
                    max: v => v.length <= 500 || 'Max 500 characters',
                },
            }
        },
        methods: {
            changeOverlay() {
                this.$emit("changeOverlay")
            },
            hideMsg() {
                if(document.getElementById('edit-product-error-message') != null) {
                    document.getElementById('edit-product-error-message').style.display = "none";
                }
            },
            hideShowBtns() {
                document.getElementById("delete-btn").style.display = "none";
                document.getElementById("sure-btn").style.display = "block";
            },
            deleteProduct() {
                let token = cookies.get('_casLt')
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/shop_products',
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        "loginToken": token,
                        "productId": this.product.id,
                    }
                }).then(() => {
                    router.go();
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                    document.getElementById('edit-product-error-message').style.display = "block";
                })
            },
            updateProduct() {
                let token = cookies.get('_casLt')
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/shop_products',
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        "loginToken": token,
                        "productId": this.product.id,
                        "name": this.name,
                        "price": this.price,
                        "priceCode": this.priceCode,
                        "sizes": this.sizes,
                        "imageUrl": this.imageUrl,
                        "description": this.description,
                    }
                }).then(() => {
                    router.go();
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                    document.getElementById('edit-product-error-message').style.display = "block";
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
        #edit-product {
        width: 100%;
        margin-top: 30px;
        display: grid;
        justify-items: center;
        align-items: center;
        
        
        h1 {
            text-align: center;
            font-family: "Helvetica";
            margin: 10px 0;
        }

        #edit-product-form {
            width: 90vw;
            font-family: "Helvetica";

            p {
                font-family: "Helvetica";
                justify-self: start;
                margin: 10px 0;
                color: red;
            }

            #edit-product-error-message {
                display: none;
                color: rgb(212, 0, 0);
                margin: 3vh 0;
            }

            #form-btns {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                #sure-btn {
                    display: none;
                }
            }

            #form-btns > * {
                margin-top: 10px;
            }
        }

        #markdown-code {
            width: 90vw;
            margin-top: 5vh;
            border: 3px solid white;
            border-radius: 30px;
            color: white;
            
            h1 {
                margin: 0;
                justify-self: center;
                text-decoration: underline;
            }

            h3 {
                margin-top: 3vh;
                margin-left: 1vw;
            }

            #markdown-list-item > * {
                color: white;
            }

            #markdown-list-item > #list-item-div > h4 {
                margin-top: 3vh;
                color: #e89f4c;
            }
        }
    }

@media screen and (min-width: 1000px) {
    #edit-product {
        
        h1 {
            justify-self: start;
            margin-left: 15vw;
        }

        p {
            justify-self: start;
            margin-left: 15vw;
        }

        #edit-product-form {
            width: 60vw;
        }

        #markdown-code {
            width: 60vw;
        }
    }
}
</style>